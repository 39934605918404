import React from "react"
import Layout from "../components/Layout"
import SEO from "../components/SEO"

const contact = () => {
  return (
    <Layout>
      <SEO title="Contact"/>
      <section className="contact-page">
        <article className="contact-form">
          <h2>Get in touch</h2>
          <form name="contact" method="post" data-netlify="true" data-netlify-honeypot="bot-field">
            <div className="form-group">
              <input type="text" placeholder="name" name="name" className="form-control" required aria-required="true" />
              <input type="email" placeholder="email" name="email" className="form-control" required aria-required="true" />
              <textarea name="message" rows="5" placeholder="message" className="form-control" required aria-required="true" ></textarea>
            </div>
            <button type="submit" className="submit-btn btn">Send Message</button>
            <input type="hidden" name="form-name" value="contact" />
          </form>
        </article>
      </section>
    </Layout>
  )
}

export default contact
